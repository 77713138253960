export const banciRules = {
  shiftName: [
    { required: true, message: '输入班次名称', trigger: 'blur' }
  ],
  startTime: [
    { required: true, message: '选择开始时间', trigger: 'blur' }
  ],
  endTime: [
    { required: true, message: '选择结束时间', trigger: 'blur' }
  ],
}

export const zhouqiRules = {
  perName: [
    { required: true, message: '输入周期名称', trigger: 'blur' }
  ],
  // perDays: [
  //   { required: true, message: '选择周期天数', trigger: 'blur' },
  // ]
}
