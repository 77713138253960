<template>
  <div class="add-rank-dialog">
    <page-dialog @changeDialogVisible="changeDialogVisible" :dialogVisible="dialogVisible" title="适配门店">
      <el-input size="small" v-model="input" placeholder="请输入门店名称" />
      <div class="shop-list">
        <div class="titls">
          <div>门店名称</div>
          <div>状态</div>
        </div>
        <div class="el-scrollbar">
          <el-scrollbar height="250px">
            <el-checkbox-group @change="handleCheckChange" v-model="checkList">
              <template :key="item" v-for="item in shopList">
                <el-checkbox @click="checkBoxClick(item)" @change="handleCheckBoxChange" :label="item.shopId">
                  <div class="item">
                    <div class="left">
                      <div class="name">{{ item.shopName }}</div>
                    </div>
                    <div class="right">
                      <el-tag size="small" v-if="checkList.findIndex(ele => ele == item.shopId) != -1" class="ml-2"
                        type="success">
                        已分配</el-tag>
                      <el-tag size="small" v-else class="ml-2" type="info">未分配</el-tag>
                    </div>
                  </div>
                </el-checkbox>
              </template>
            </el-checkbox-group>
          </el-scrollbar>
        </div>
        <div class="el-pagination">
          <el-pagination v-model:currentPage="currentPage.page" v-model:page-size="currentPage.pagesize"
            :page-sizes="[10, 20, 30, 40]" layout="total, sizes, prev, pager, next, jumper" :total="shopCount"
            @size-change="handleSizeChange" @current-change="handleCurrentChange" :pager-count="3" />
        </div>
      </div>
    </page-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, computed } from 'vue'
import PageDialog from '@/components/page-dialog/page-dialog.vue'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
import { rankCategory } from './config'
import emitter from '@/utils/eventbus'
import { react } from '@babel/types'
import { useState } from '@/hooks'
import { nextTick } from 'process'
import { getShopList } from '@/service/main/shop'
export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    shiftId: {
      type: Number,
      default: 0
    }
  },
  components: {
    PageDialog
  },
  emits: ['changeDialogVisible'],
  setup(props, { emit }) {
    const store = useStore()
    const changeDialogVisible = (flag) => {
      emit('changeDialogVisible', flag)
    }

    const formState = useState('shop', ['shopCount'])
    const checkList = ref([])
    const shopList = ref([])
    const scheduleBindShopIdList = computed(() => store.state.staff.scheduleBindShopIdList)

    watch(scheduleBindShopIdList, (n) => {
      checkList.value = []
      if (n != null) {
        n.forEach(item => {
          checkList.value.push(item)
        })
      }
    }, {
      deep: true
    })

    const currentPage = reactive({
      page: 1,
      pagesize: 10,
    })

    const currentOptShop = reactive({
      id: 0,
      flag: false
    })

    watch(currentPage, () => {
      initPage()
    }, {
      deep: true
    })

    const handleSizeChange = (number) => {
      currentPage.pagesize = number
    }

    const handleCurrentChange = (number) => {
      currentPage.page = number
    }
    const initPage = async () => {
      const res = await getShopList({
        companyId: COMPANY_ID,
        page: currentPage.page,
        pagesize: currentPage.pagesize
      })
      let list=res.data.allShops
      let newList=list.filter(ele=>ele.runningStatus==1)
      shopList.value = newList

      store.dispatch('staff/getScheduleBindListAction', props.shiftId)
    }
    initPage()

    const handleCheckBoxChange = (flag) => {
      currentOptShop.flag = flag
      nextTick(() => {
        if (flag) {
          store.dispatch('staff/shopScheduleBindAction', [currentOptShop.id, props.shiftId])
        } else {
          store.dispatch('staff/removeShopScheduleBindAction', [currentOptShop.id, props.shiftId])
        }
      })
    }

    const handleCheckChange = (value) => {
    }

    const checkBoxClick = (item) => {
      currentOptShop.id = item.shopId
    }



    return {
      checkBoxClick,
      changeDialogVisible,
      rankCategory,
      ...formState,
      checkList,
      handleSizeChange,
      handleCurrentChange,
      currentPage,
      handleCheckBoxChange,
      handleCheckChange,
      currentOptShop,
      shopList,
      scheduleBindShopIdList


    }

  }
})
</script>

<style scoped lang="less">
.add-rank-dialog {
  &:deep(.is-horizontal) {
    display: none !important;
  }

  &:deep(.el-select) {
    width: 100%;
  }

  &:deep(.el-dialog__body) {
    padding-top: 20px;
  }

  .el-scrollbar {
    padding: 0 4px;

  }

  .shop-list {
    margin-top: 10px;

    .titls {
      display: flex;
      margin-bottom: 20px;
      padding: 0 10px;
      background-color: rgb(239, 239, 239);

      div {
        flex: 1;
        line-height: 40px;

      }
    }

    &:deep(.el-checkbox) {
      display: inline-block;
      width: 100% !important;

      .el-checkbox__label {
        display: inline-block;
        width: 100%;

        .item {
          width: 97%;
          display: flex;
          align-items: center;

          &>div {
            flex: 1;
          }

          .left {
            display: flex;

            .name {
              margin-right: 20px;
            }
          }

          .right {
            position: relative;
            left: -8px;
          }
        }
      }
    }
  }
}
</style>




