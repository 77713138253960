<template>
  <div class="top-content">
    <el-button v-if="isWeekAdd" class="addBtn" @click="handleAddScheduleClick" type="primary" v-has="'staff:cycle:add'">
      <el-icon>
        <plus />
      </el-icon>添加周期
    </el-button>
    <el-button v-else class="addBtn" @click="handleAddScheduleClick" type="primary" v-has="'staff:classes:add'">
      <el-icon>
        <plus />
      </el-icon>添加班次
    </el-button>

    <!-- <el-select @change="handleShopChange" clearable v-model="ruleForm.shopId" class="m-2" placeholder="请选择门店" size="large">
      <el-option v-for="item in shopList" :key="item.shopId" :label="item.shopName" :value="item.shopId"></el-option>
    </el-select> -->
    <add-schedule-dialog v-if="addScheduleDialogVisible" @changeDialogVisible="changeAddScheduleDialogVisible"
      :dialogVisible="addScheduleDialogVisible"></add-schedule-dialog>
    <add-week-schedule-dialog v-if="addWeekScheduleDialogVisible"
      @changeDialogVisible="changeAddWeekScheduleDialogVisible" :dialogVisible="addWeekScheduleDialogVisible">
    </add-week-schedule-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import AddScheduleDialog from './cpns/add-schedule-dialog/add-schedule-dialog.vue'
import AddWeekScheduleDialog from './cpns/add-week-schedule-dialog/add-week-schedule-dialog.vue'
import { getBelongShop } from '@/service/main/staff'
import { COMPANY_ID } from '@/common/constant'
import { emit } from 'process'
export default defineComponent({
  props: {
    isWeekAdd: {
      type: Boolean,
      default: false
    }
  },
  components: {
    AddScheduleDialog,
    AddWeekScheduleDialog
  },
  setup(props,{emit}) {
    const addScheduleDialogVisible = ref(false)
    const addWeekScheduleDialogVisible = ref(false)

    const changeAddScheduleDialogVisible = (flag) => {
      addScheduleDialogVisible.value = flag
    }

    const handleAddScheduleClick = () => {
      if (props.isWeekAdd == true) {
        addWeekScheduleDialogVisible.value = true
      } else {
        addScheduleDialogVisible.value = true
      }
    }

    const changeAddWeekScheduleDialogVisible = (flag) => {
      addWeekScheduleDialogVisible.value = flag
    }

    const shopList = ref([])
    const initPage = async () => {
      // const res = await getBelongShop(COMPANY_ID)
      // shopList.value = res.data.list
    }
    initPage()

    const ruleForm=reactive({
      shopId:""
    })

    const handleShopChange=value=>{
      emit('shopChange',value)
    }


    return {
      handleShopChange,
      ruleForm,
      shopList,
      addScheduleDialogVisible,
      changeAddScheduleDialogVisible,
      handleAddScheduleClick,
      addWeekScheduleDialogVisible,
      changeAddWeekScheduleDialogVisible
    }

  }
})
</script>

<style scoped lang="less">
.top-content {
  margin-bottom: 20px;

  .addBtn {
    margin-right: 20px;
  }
}
</style>




