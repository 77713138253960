<template>
  <div class="add-week-schedule-dialog">
    <page-dialog :isShowFooter="true" @changeDialogVisible="changeDialogVisible" :dialogVisible="dialogVisible"
      title="添加周期">
      <el-form label-position="left" ref="ruleFormRef" :model="ruleForm" :rules="zhouqiRules" label-width="100px"
        class="demo-ruleForm">
        <el-form-item label="周期名称:" prop="perName">
          <el-input v-model="ruleForm.perName" placeholder="名称" />
        </el-form-item>
        <el-form-item label="周期天数:" prop="perDays">
          <el-input @input="handleDayNumClick" min="1" type="number" v-model="ruleForm.perDays" placeholder="天数">
            <template #suffix>| 天</template>
          </el-input>
          <div class="time-list">
            <el-scrollbar height="250px">
              <template :key="item" v-for="(item, index) in weekDayList">
                <option-time :index="index" :ruleForm="weekDayList[index]"></option-time>
              </template>
            </el-scrollbar>
          </div>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="el-save-btn">
          <el-button @click="handleSaveClick(ruleFormRef)" size="small" type="primary">保存</el-button>
        </div>
      </template>
    </page-dialog>
  </div>
</template>

<script lang="js">
import {
  defineComponent,
  reactive,
  ref,
  computed,
  nextTick
} from 'vue'
import PageDialog from '@/components/page-dialog/page-dialog.vue'
import OptionTime from './option-time/option-time.vue'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
import { zhouqiRules } from '../../config'
import { ElMessage } from 'element-plus'

export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }

  },
  components: {
    PageDialog,
    OptionTime
  },
  emits: ['changeDialogVisible'],
  setup(props, {
    emit
  }) {
    const store = useStore()
    const changeDialogVisible = (flag) => {
      emit('changeDialogVisible', flag)
    }

    const scheduleWeekList = computed(() => store.state.staff.scheduleWeekList)

    const handleDayNumClick = (value) => {
      if (Number(value) < 1) {
        ruleForm.perDays = 1
      } else if (Number(value) > 31) {
        ruleForm.perDays = 31
      }

      weekDayList.value = []
      nextTick(() => {
        for (let i = 0; i < ruleForm.perDays; i++) {
          weekDayList.value.push({
            id: null
          })
        }
      })

    }

    const weekDayList = ref([
      {
        id: null
      }
    ])

    const ruleForm = reactive({
      perName: '',
      perDays: 1,

    })

    const emptyForm = () => {
      ruleForm.perName = ''
      ruleForm.perDays = 1
      weekDayList.value = [
        {
          id: null
        }
      ]
    }

    const handleSaveClick = async (formEl = undefined) => {
      if (!formEl) return
      await formEl.validate(async (valid, fields) => {
        if (valid) {
          let flag = true
          let list = weekDayList.value
          for (let item of list) {
            if (item.id == null) {
              flag = false
              continue;
            }
          }

          if (flag == false) {
            ElMessage({
              message: '请完整选择班次!',
              type: 'warning',
            })

            return
          }

          let oldList = scheduleWeekList.value
          let perName = ruleForm.perName
          let index = oldList.findIndex(ele => ele.perName == perName)
          if (index != -1) {
            ElMessage({
              message: '周期名称重复!',
              type: 'warning',
            })
            return
          }

          store.dispatch('staff/addScheduleWeekAction', {
            weekParams: { ...ruleForm },
            weekDayList: weekDayList.value,
            companyId: COMPANY_ID
          })
          changeDialogVisible(false)
          emptyForm()
          // console.log('submit!')
        } else {
          // console.log('error submit!', fields)
        }
      })
    }

    const ruleFormRef = ref()

    return {
      ruleFormRef,
      changeDialogVisible,
      ruleForm,
      handleSaveClick,
      weekDayList,
      handleDayNumClick,
      emptyForm,
      zhouqiRules

    }

  }
})
</script>

<style scoped lang="less">
.add-week-schedule-dialog {
  &:deep(.option-time) {
    margin-top: 10px;
  }

  .name,
  .day-num {
    margin-bottom: 20px;

    .title {
      min-width: 80px;
    }

    display: flex;
    align-items: center;
  }
}
</style>
