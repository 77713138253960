export const breadcrumbList = [
  {
    name: '员工',
    path: ''
  },
  {
    name: '班次设置',
    path: ''
  }
]
