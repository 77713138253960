const contentTableConfig = {
  propList: [
    {
      prop: 'shiftName',
      label: '班次名称',
      minWidth: '150',
      slotName: 'shiftName'
    },
    { prop: 'startTime', label: '时间', minWidth: '150' , slotName: 'startTime'},

    // {
    //   prop: 'shops',
    //   label: '适配门店',
    //   minWidth: '0',
    //   slotName: 'shops'
    // },

    { label: '操作', minWidth: '120', slotName: 'handler' }
  ],
  showIndexColumn: false,
  showSelectColumn: false
}

const contentWeekTableConfig = {
  propList: [
    {
      prop: 'perName',
      label: '周期名称',
      minWidth: '150',
      slotName: 'perName'
    },
    {
      prop: 'shiftList',
      label: '排班明细',
      minWidth: '150',
      slotName: 'shiftList'
    },
    // {
    //   prop: 'useQty',
    //   label: '适配门店',
    //   minWidth: '100',
    //   slotName: 'useQty'
    // },
    { label: '操作', minWidth: '120', slotName: 'handler' }
  ],
  showIndexColumn: false,
  showSelectColumn: false
}

export { contentTableConfig, contentWeekTableConfig }
