<template>
  <div class="tabs">
    <top-content @shopChange="shopChange"></top-content>
    <page-content :contentTableConfig="contentTableConfig" :dataList="shiftsettingstaffList">
      <template #startTime="scope">
        {{ scope.row.startTime + '-' + scope.row.endTime }}
      </template>
      <template #handler="scope">
        <div v-if="scope.row.isys != 1" class="handle-btns">
          <el-button plain size="mini" @click="handleEditClick(scope.row)" v-has="'staff:classes:edit'">编辑</el-button>
          <el-button plain size="mini" @click="handleEditScheduleClick(scope.row, 2)" v-has="'staff:classes:delete'">删除
          </el-button>
        </div>
      </template>
    </page-content>

    <distribute-dialog v-if="distributeDialogVisible" :shiftId="currentEditShiftId"
      :dialogVisible="distributeDialogVisible" @changeDialogVisible="changeDistributeDialogVisible"></distribute-dialog>

    <edit-schedule-dialog v-if="editScheduleDialogVisible" :editInfo="currentEditShiftInfo"
      @changeDialogVisible="changeEditScheduleDialogVisible" :dialogVisible="editScheduleDialogVisible">
    </edit-schedule-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed, onUnmounted } from 'vue'
import distributeDialog from './cpns/distribute-dialog/distribute-dialog.vue';
import TopContent from './cpns/top-content/top-content.vue'
import PageContent from '@/components/page-content2/src/page-content.vue';
import { contentTableConfig, contentWeekTableConfig } from '../config';
import { COMPANY_ID } from '@/common/constant';
import { useStore } from 'vuex';
import emitter from '@/utils/eventbus';
import editScheduleDialog from './cpns/edit-schedule-dialog/edit-schedule-dialog.vue';
export default defineComponent({
  props: {

  },
  components: {
    TopContent,
    PageContent,
    distributeDialog,
    editScheduleDialog
  },
  setup() {

    const store = useStore()
    const currentEditShiftId = ref('')
    const distributeDialogVisible = ref(false)
    const shiftsettingstaffList = computed(() => store.state.staff.shiftsettingstaffList)
    const changeDistributeDialogVisible = (flag) => {
      distributeDialogVisible.value = flag
    }

    const editScheduleDialogVisible = ref(false)
    const changeEditScheduleDialogVisible = (flag) => {
      editScheduleDialogVisible.value = flag
    }

    const currentEditShiftInfo = ref('')

    const handleEditClick = (item) => {
      currentEditShiftInfo.value = item
      editScheduleDialogVisible.value = true
      // console.log(item);
    }


    const handleEditScheduleClick = (item, type) => {
      store.dispatch('staff/updateScheduleStatusAction', item.id)
    }

    const handleDistributeShopClick = (item) => {
      currentEditShiftId.value = item.id
      distributeDialogVisible.value = true
    }

    const ruleForm = reactive({
      shopId: 0
    })


    const shopChange = value => {
      // console.log(value);
      let shopId
      if(value.length==0){
        shopId=0
      }else{
        shopId=value
      }
      ruleForm.shopId = shopId
      initPage()
    }

    const initPage = () => {
      store.dispatch('staff/getshiftsettingstaffListAction', {
        companyId: COMPANY_ID,
        shopId: ruleForm.shopId
      })
    }
    initPage()

    emitter.on('refreshSddScheduleList', () => {
      initPage()
    })

    onUnmounted(() => {
      emitter.off('refreshSddScheduleList')
    })


    return {
      ruleForm,
      shopChange,
      contentTableConfig,
      contentWeekTableConfig,
      COMPANY_ID,
      initPage,
      shiftsettingstaffList,
      currentEditShiftId,
      handleEditScheduleClick,
      changeDistributeDialogVisible,
      distributeDialogVisible,
      handleDistributeShopClick,
      editScheduleDialogVisible,
      changeEditScheduleDialogVisible,
      handleEditClick,
      currentEditShiftInfo,

    }

  }
})
</script>

<style scoped lang="less">
.tabs {
  margin-top: 20px;

  .schedule_details {}
}
</style>
