<template>
  <div class="shift-settings">
    <tabs></tabs>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import { breadcrumbList } from './config'
import Tabs from './cpns/tabs/tabs.vue'
export default defineComponent({
  props: {

  },
  components: {
    Breadcrumb,
    Tabs
  },
  setup () {


    return {
      breadcrumbList
    }

  }
})
</script>

<style scoped lang="less">
.shift-settings {
}
</style>




