<template>
  <div class="add-schedule-dialog">
    <page-dialog :isShowFooter="true" @changeDialogVisible="changeDialogVisible" :dialogVisible="dialogVisible"
      @handleSaveClick="handleSaveClick" title="修改班次">
      <div class="form">
        <div class="name">
          <div class="title">班次名称:</div>
          <el-input v-model="ruleForm.shiftName" placeholder="班次名称" />
        </div>

        <div class="time">
          <div class="title">班次时间:</div>

          <el-time-select v-model="ruleForm.startTime" :max-time="ruleForm.endTime" class="startTime" placeholder="开始时间"
            start="08:30" step="00:15" end="18:30"></el-time-select>
          <el-time-select v-model="ruleForm.endTime" :min-time="ruleForm.startTime" placeholder="结束时间" start="08:30"
            step="00:15" end="18:30"></el-time-select>
        </div>
      </div>
    </page-dialog>
  </div>
</template>

<script lang="js">
import {
  defineComponent,
  reactive,
  ref,
  watch
} from 'vue'
import PageDialog from '@/components/page-dialog/page-dialog.vue'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    editInfo: {
      type: Object,
      default() {
        return {}
      }
    }

  },
  components: {
    PageDialog
  },
  emits: ['changeDialogVisible'],
  setup(props, {
    emit
  }) {
    const store = useStore()
    const changeDialogVisible = (flag) => {
      emit('changeDialogVisible', flag)
    }
    const ruleForm = reactive({
      shiftName: '',
      startTime: '',
      endTime: '',
      companyId: COMPANY_ID,
      status: 1,
      id: ''
    })

    watch(() => props.editInfo, (n) => {
      // console.log(n);
      if (n != null) {
        ruleForm.shiftName = n.shiftName
        ruleForm.startTime = n.startTime,
          ruleForm.endTime = n.endTime
        ruleForm.status = n.status
        ruleForm.id = n.id
      }
    },{
      immediate:true
    })

    const handleSaveClick = () => {
      store.dispatch('staff/updateScheduleInfoAction', { ...ruleForm })
    }

    return {
      ruleForm,
      changeDialogVisible,
      handleSaveClick
    }

  }
})
</script>

<style scoped lang="less">
.add-schedule-dialog {
  .form {
    .name {
      display: flex;
      align-items: center;

      &:deep(.el-input) {
        max-width: 260px;
      }

      .tips {
        margin-left: 20px;
        min-width: 100px;
        white-space: nowrap;
      }
    }

    .time {
      margin-top: 20px;
      display: flex;
      align-items: center;

      &:deep(.el-input) {
        max-width: 200px;
      }

      .startTime {
        margin-right: 20px;
      }
    }

    .name,
    .time {
      .title {
        width: 70px;
      }
    }
  }
}
</style>
