<template>
  <div v-if="dialogVisible" class="add-schedule-dialog">
    <page-dialog :isShowFooter="true" @changeDialogVisible="changeDialogVisible" :dialogVisible="dialogVisible"
      title="添加班次" width="500px">
      <el-form label-position="left" ref="ruleFormRef" :model="ruleForm" :rules="banciRules" label-width="90px"
        class="demo-ruleForm" >
        <el-form-item label="班次名称:" prop="shiftName">
          <el-input v-model="ruleForm.shiftName" placeholder="班次名称" />
        </el-form-item>
        <el-form-item label="班次时间:" prop="startTime">
          <div class="time">
            <el-form-item prop="startTime">
              <el-time-select v-model="ruleForm.startTime" :max-time="ruleForm.endTime" class="startTime"
                placeholder="开始时间" start="00:00" step="00:10" end="24:00"></el-time-select>
            </el-form-item>
            <span class="dao">到</span>
            <el-form-item prop="endTime">
              <el-time-select v-model="ruleForm.endTime" :min-time="ruleForm.startTime" placeholder="结束时间" start="00:00"
                step="00:10" end="24:00"></el-time-select>
            </el-form-item>
          </div>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="el-save-btn">
          <el-button @click="handleSaveClick(ruleFormRef)" size="small" type="primary">保存</el-button>
        </div>
      </template>
    </page-dialog>
  </div>
</template>

<script lang="js">
import {
  defineComponent,
  reactive,
  ref,
  computed
} from 'vue'
import PageDialog from '@/components/page-dialog/page-dialog.vue'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
import { banciRules } from '../../config'
import { addSchedule } from '@/service/main/staff'
import emitter from '@/utils/eventbus'
import { ElMessage } from 'element-plus'

export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }

  },
  components: {
    PageDialog
  },
  emits: ['changeDialogVisible'],
  setup(props, {
    emit
  }) {
    const store = useStore()
    const changeDialogVisible = (flag) => {
      emit('changeDialogVisible', flag)
    }

    const shiftsettingstaffList = computed(() => store.state.staff.shiftsettingstaffList)

    const ruleForm = reactive({
      shiftName: '',
      startTime: '',
      endTime: '',
      companyId: COMPANY_ID
    })

    const handleSaveClick = async (formEl = undefined) => {
      if (!formEl) return
      await formEl.validate(async (valid, fields) => {
        if (valid) {
          let shiftName = ruleForm.shiftName
          let oldList = shiftsettingstaffList.value
          let index = oldList.findIndex(ele => ele.shiftName == shiftName)
          if (index != -1) {
            ElMessage({
              message: '班次名称重复!',
              type: 'warning',
            })
            return
          }

          const res = await addSchedule({ ...ruleForm })
          if (res.code == 0) {
            ElMessage({
              message: '操作成功!',
              type: 'warning',
            })
            changeDialogVisible(false)
            emitter.emit('refreshSddScheduleList')
          }
          // console.log('submit!')
        } else {
          // console.log('error submit!', fields)
        }
      })
    }

    const ruleFormRef = ref()

    return {
      ruleFormRef,
      ruleForm,
      changeDialogVisible,
      handleSaveClick,
      banciRules,
      shiftsettingstaffList

    }

  }
})
</script>

<style scoped lang="less">
.add-schedule-dialog {
  font-size: 12px;

  &:deep(.el-select) {
    max-width: 140px;
  }

  .name {
    display: flex;
    align-items: center;

    &:deep(.el-input) {
      max-width: 260px;
    }

    .tips {
      margin-left: 20px;
      min-width: 100px;
      white-space: nowrap;
    }
  }

  .time {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .dao {
      margin: 0 16px;
      display: inline-block;
    }

    &:deep(.el-input) {
      max-width: 200px;
    }


  }

  .name,
  .time {
    .title {
      width: 70px;
    }
  }
}
</style>
